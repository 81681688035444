import Carousel from "react-bootstrap/Carousel";
import SampleImg from "../assets/img/Sample.png";
import { useEffect, useState } from "react";

function ImageCarousel(props) {
	const [ImageList, setImageList] = useState([]);

	useEffect(() => {
		console.log(props.onImageList);
		if (props.onImageList !== undefined && props.onImageList.length !== 0) {
			// let ImageArray = JSON.parse(props.onImageList);
			console.log("IMAGE LIST:", props.onImageList);

			if (Array.isArray(JSON.parse(props.onImageList))) {
				setImageList(JSON.parse(props.onImageList));
			} else {
				// Handle the case when onImageList is not an array
			}
		}
	}, [props]);

	useEffect(() => {
		console.log(ImageList);
	}, [ImageList]);

	return (
		<Carousel interval={null}>
			{ImageList != null &&
				ImageList.map((url) => {
					return (
						<Carousel.Item className='img-carousel-item'>
							<img
								src={"https://services-parkncharge.sysnetph.com/uploads/" + url}
								loading='eager'
							/>
						</Carousel.Item>
					);
				})}
		</Carousel>
	);
}

export default ImageCarousel;
