import { Box, Typography, IconButton, Button } from "@mui/material";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useState } from "react";

const BottomModal = (props) => {
	const [IsMinimized, setIsMinimized] = useState(true);

	const toggleCancelNavigation = () => {
		props.onSetNavigationModal(false);
		props.onCancelNavigation();
	};

	const toggleIsMinimized = (bool) => {
		setIsMinimized(bool);
	};

	return (
		<Box
			sx={{
				position: "fixed",
				bottom: 0,
				transform: {
					xs: "translate(0, 0)",
					md: "translate(12.5%, 0)",
					lg: "translate(50%, 0)",
				},
				backgroundColor: "white",
				width: {
					xs: "100%",
					md: "80%",
					lg: "50%",
				},
				boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);",
				borderRadius: "32px 32px 0 0",
				paddingBottom: { xs: "10vh", lg: 0 },
				zIndex: 3,
			}}>
			<Box
				sx={{
					borderRadius: "32px 32px 0 0",
					backgroundColor: "#FFFFFF",
					padding: "16px",
					display: "flex",
					alignItems: IsMinimized ? "center" : "flex-start",
					justifyContent: "space-between",
				}}>
				<Typography
					variant='h6'
					sx={{
						whiteSpace: IsMinimized && "nowrap",
						width: IsMinimized ? "80%" : "100%",
						overflow: IsMinimized && "hidden",
						textOverflow: IsMinimized && "ellipsis",
					}}>
					{IsMinimized ? "Navigating: " + props.onNavDestination : props.onNavDestination}
				</Typography>
				{!IsMinimized ? (
					<IconButton
						aria-label='close'
						onClick={() => setIsMinimized(true)}>
						<DoNotDisturbOnIcon />
					</IconButton>
				) : (
					<IconButton
						aria-label='close'
						onClick={() => setIsMinimized(false)}>
						<KeyboardArrowRightIcon />
					</IconButton>
				)}

				{/* <Box sx={{ border: "solid 2px gray", width: "80%", borderRadius: "16px" }}></Box> */}
			</Box>
			{!IsMinimized && (
				<Box sx={{ padding: "8px 32px 8px 32px" }}>
					<Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
						<Typography variant='subtitle2'>Destination:</Typography>
						<Typography
							variant='subtitle2'
							sx={{ textAlign: "end" }}>
							{props.onNavigationDetails.end_address} away
						</Typography>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant='subtitle2'>Distance:</Typography>
						<Typography variant='subtitle2'>{props.onNavigationDetails.distance.text} away</Typography>
					</Box>
					<Box sx={{ display: "flex", justifyContent: "space-between" }}>
						<Typography variant='subtitle2'>Estimated Travel Time:</Typography>
						<Typography variant='subtitle2'>{props.onNavigationDetails.duration.text}</Typography>
					</Box>
					<Button
						onClick={toggleCancelNavigation}
						variant='contained'
						sx={{ width: "100%", marginTop: "32px" }}>
						Stop Navigation
					</Button>
				</Box>
			)}
		</Box>
	);
};

export default BottomModal;
