import { Box, IconButton, FormGroup, FormControlLabel, Checkbox, FormLabel, Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect, useState } from "react";

const FilterModal = (props) => {
	const [IsAdvanceVisible, setIsAdvanceVisible] = useState(false);
	const [Capabilities, setCapabilities] = useState([]);
	const [ConnectorTypes, setConnectorTypes] = useState([]);
	const [Facilities, setFacilities] = useState([]);
	const [ParkingTypes, setParkingTypes] = useState([]);
	const [PaymentTypes, setPaymentTypes] = useState([]);

	const toggleAdvance = () => {
		setIsAdvanceVisible(!IsAdvanceVisible);
	};

	const toggleFilter = () => {
		console.log("X");
		props.onSocket();
	};

	useEffect(() => {
		if (props.onFilterDefaultData !== undefined) {
			console.log(props.onFilterDefaultData);
			setCapabilities(props.onFilterDefaultData.capabilities);
			setConnectorTypes(props.onFilterDefaultData.connector_types);
			setFacilities(props.onFilterDefaultData.facilities);
			setParkingTypes(props.onFilterDefaultData.parking_types);
			setPaymentTypes(props.onFilterDefaultData.payment_types);
		}
		console.log(props.onFilterDefaultData);
	}, [props]);

	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				position: "absolute !important",
				top: {
					xs: "16%",
					lg: "19%",
				},
				left: {
					xs: "0%",
					lg: ".75%",
				},
				width: {
					xs: "100%",
					md: "50%",
					lg: "30%",
				},
				zIndex: 2,
				height: "80%",
				backgroundColor: "white",
				boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
			}}>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
				<IconButton onClick={() => props.onSetModalVisibility(false)}>
					<CloseIcon />
				</IconButton>
				<Typography
					variant='subtitle2'
					sx={{ fontSize: "16px" }}>
					Filter
				</Typography>
				<IconButton sx={{ visibility: "hidden" }}>XX</IconButton>
			</Box>
			<Box sx={{ display: "flex", flexDirection: "column", padding: "16px", height: "100%", overflowY: "auto" }}>
				<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
					<FormLabel
						sx={{ marginBottom: "unset", marginTop: "16px" }}
						component='legend'>
						Power Types
					</FormLabel>
					<FormControlLabel
						control={<Checkbox />}
						label='All'
					/>
					<FormControlLabel
						control={<Checkbox />}
						label='AC'
					/>
					<FormControlLabel
						control={<Checkbox />}
						label='DC'
					/>
				</FormGroup>
				<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
					<FormLabel
						sx={{ marginBottom: "unset", marginTop: "16px" }}
						component='legend'>
						Connector Types
					</FormLabel>
					<FormControlLabel
						control={<Checkbox />}
						label='All'
					/>
					{ConnectorTypes?.map((type) => {
						return (
							<FormControlLabel
								control={<Checkbox />}
								label={type.description}
							/>
						);
					})}
				</FormGroup>
				<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
					<FormLabel
						sx={{ marginBottom: "unset", marginTop: "16px" }}
						component='legend'>
						Facilities
					</FormLabel>
					<FormControlLabel
						control={<Checkbox />}
						label='All'
					/>
					{Facilities?.map((facility) => {
						return (
							<FormControlLabel
								control={<Checkbox />}
								label={facility.description}
							/>
						);
					})}
				</FormGroup>
				{IsAdvanceVisible && (
					<Box sx={{ marginTop: "16px", display: "flex", flexDirection: "column" }}>
						<Typography
							variant='subtitle2'
							sx={{ alignSelf: "center" }}>
							Advance Filters
						</Typography>
						<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
							<FormLabel
								sx={{ marginBottom: "unset" }}
								component='legend'>
								Capabilities
							</FormLabel>
							<FormControlLabel
								control={<Checkbox />}
								label='All'
							/>
							{Capabilities?.map((capability) => {
								return (
									<FormControlLabel
										control={<Checkbox />}
										label={capability.description}
									/>
								);
							})}
						</FormGroup>
						<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
							<FormLabel
								sx={{ marginBottom: "unset", marginTop: "16px" }}
								component='legend'>
								Payment Types
							</FormLabel>
							{}
							<FormControlLabel
								control={<Checkbox />}
								label='All'
							/>
							{PaymentTypes?.map((type) => {
								return (
									<FormControlLabel
										control={<Checkbox />}
										label={type.description}
									/>
								);
							})}
						</FormGroup>
						<FormGroup sx={{ display: "flex", flexDirection: "row" }}>
							<FormLabel
								sx={{ marginBottom: "unset", marginTop: "16px" }}
								component='legend'>
								Parking Types
							</FormLabel>
							<FormControlLabel
								control={<Checkbox />}
								label='All'
							/>
							{ParkingTypes?.map((type) => {
								return (
									<FormControlLabel
										control={<Checkbox />}
										label={type.description}
									/>
								);
							})}
						</FormGroup>
					</Box>
				)}
				<Button
					sx={{ display: "flex", alignSelf: "center", marginTop: "16px" }}
					onClick={() => toggleAdvance()}>
					{IsAdvanceVisible ? "Hide Advance Filters" : "	Show Advance Filters"}
				</Button>
			</Box>
			<Box sx={{ padding: "8px", display: "flex", justifyContent: "space-between" }}>
				<Button>Clear</Button>
				<Button
					variant='contained'
					onClick={toggleFilter}>
					Filter
				</Button>
			</Box>
		</Box>
	);
};

export default FilterModal;
