import React, { useEffect, useMemo, useState, useRef } from "react";
import {
	GoogleMap,
	Marker,
	useLoadScript,
	MarkerClusterer,
	DirectionsService,
	DirectionsRenderer,
	InfoWindow,
} from "@react-google-maps/api";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import Tooltip from "@mui/material/Tooltip";
import Loading from "./Loading";
import UserLocationImg from "../assets/img/user_location.png";
import ClustererIcon from "../assets/img/MarkerClusterer.png";
import "../assets/css/map.css";
// import { Button } from "react-bootstrap";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import DeleteIcon from "@mui/icons-material/Delete";
import AlarmIcon from "@mui/icons-material/Alarm";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import StoreIcon from "@mui/icons-material/Store";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterModal from "./FilterModal";

import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";

import FileCopyIcon from "@mui/icons-material/FileCopyOutlined";
import SaveIcon from "@mui/icons-material/Save";
import PrintIcon from "@mui/icons-material/Print";
import ShareIcon from "@mui/icons-material/Share";

import MessageIcon from "@mui/icons-material/Message";
import CallIcon from "@mui/icons-material/Call";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";

const actions = [
	{ icon: <CallIcon />, name: "Call" },
	{ icon: <MessageIcon />, name: "Message" },
];

const MapComponent = (props) => {
	const [EVSEList, setEVSEList] = useState([]);
	const [ZoomValue, setZoomValue] = useState(14);
	const [UserLocation, setUserLocation] = useState({});
	const [CenterLocation, setCenterLocation] = useState({});
	const [map, setMap] = useState(null);
	const [directionsResponse, setDirectionsResponse] = useState(null);
	const [infoWindowOpen, setInfoWindowOpen] = useState(false);
	const [activeMarker, setActiveMarker] = useState(null);
	const [Destination, setDestination] = useState();
	const [IsFilterModalVisible, setIsFilterModalVisible] = useState(false);

	const directionsServiceRef = useRef(null);
	const directionsRendererRef = useRef(null);

	const toggleCPOButton = () => {
		window.open("https://stg-cpo.sysnetph.com/signup", "_blank");
	};

	const toggleFilterModal = (bool) => {
		setIsFilterModalVisible(bool);
	};

	const ClearNavigation = () => {
		setDirectionsResponse(null);
	};

	const calculateRoute = (origin, destination, isLocal = undefined) => {
		if (!origin || !destination) return;
		setDestination(destination);
		directionsServiceRef.current = new window.google.maps.DirectionsService();
		directionsServiceRef.current.route(
			{
				origin: origin,
				destination: destination,
				travelMode: window.google.maps.TravelMode.DRIVING,
			},
			(result, status) => {
				console.log("[RESULT]:", result);
				if (status === window.google.maps.DirectionsStatus.OK) {
					if (isLocal === undefined) {
						setDirectionsResponse(result);
					}

					const navigationDetails = result.routes[0].legs[0];
					props.onToggleDistance(navigationDetails, ClearNavigation);
				} else {
					console.error(`Directions request failed due to ${status}`);
				}
			}
		);
	};

	useEffect(() => {
		setCenterLocation(props.onUserLocation);
		if (props.onUserLocation !== undefined) {
			props.onSetNavigationFunc(calculateRoute);
			props.onSetZoomFunc(handleSetZoom);
		}
	}, [props.onUserLocation]);

	useEffect(() => {
		// console.log("MAP DETAILS:", props.mapDetails);

		setUserLocation(props.onUserLocation);
		setEVSEList(props.mapDetails);
	}, [props]);

	const { isLoaded } = useLoadScript({
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
	});

	const handleMarkerClicked = (evseID, coord, index) => {
		console.log(evseID);
		setZoomValue(19);
		setCenterLocation(coord);
		props.onToggleModal(true, evseID);
		console.log("COORD:", coord);
		calculateRoute(UserLocation, coord, true);
	};

	const handleReCenter = () => {
		setCenterLocation({ lat: 0, lng: 0 });
	};

	const handleZoomChange = () => {
		if (map !== null) {
			setZoomValue(map.zoom);
		}
	};

	const handleSetZoom = () => {
		setTimeout(() => {
			setZoomValue(19);
			handleReCenter();
		}, 200);
	};

	useEffect(() => {
		if (CenterLocation.lat === 0 && CenterLocation.lng === 0) {
			setCenterLocation(UserLocation);
		}
	}, [CenterLocation]);

	return !isLoaded ? (
		<Loading />
	) : (
		<>
			{/* <div style={{ padding: "10px", fontSize: "16px" }}>Distance: {distance}</div> */}
			<GoogleMap
				mapContainerClassName='map-container'
				center={CenterLocation}
				zoom={ZoomValue}
				onLoad={(x) => {
					setMap(x);
				}}
				options={{
					fullscreenControl: false,
					disableDefaultUI: true,
					gestureHandling: "greedy",
					clickableIcons: false,
					rotateControl: true, // Enable rotate control
					tilt: 45, // Set tilt for a 3D perspective
				}}
				onZoomChanged={handleZoomChange}>
				{props.onIsAccurateLocation && (
					<Marker
						icon={UserLocationImg}
						position={UserLocation}
						title='This is you.'
					/>
				)}
				<MarkerClusterer
					averageCenter
					enableRetinaIcons
					styles={[
						{
							height: 75,
							url: ClustererIcon,
							width: 60,
							textSize: 16,
							anchorText: [-25, 16.75],
						},
					]}>
					{(clusterer) => {
						return EVSEList?.map(({ id, location_name, coordinates, logo }, index) => {
							// console.log(EVSEList);
							return (
								<Marker
									key={id}
									position={{
										lat: Number(coordinates.latitude),
										lng: Number(coordinates.longitude),
									}}
									icon={{
										url: logo,
										scaledSize: new window.google.maps.Size(80, 80),
									}}
									clusterer={clusterer}
									animation={window.google.maps.Animation.DROP}
									title={location_name}
									onClick={() =>
										handleMarkerClicked(
											id,
											{
												lat: Number(coordinates.latitude),
												lng: Number(coordinates.longitude),
											},
											index
										)
									}></Marker>
							);
						});
					}}
				</MarkerClusterer>
				{infoWindowOpen && (
					<InfoWindow
						position={UserLocation}
						onCloseClick={() => setInfoWindowOpen(false)}>
						<div>
							<Stack
								direction='column'
								spacing={2}
								sx={{
									width: "100%",
									justifyContent: "center",
									alignItems: "center",
									backgroundColor: "gainsboro",
									borderRadius: "16px",
									boxShadow: " 0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24);",
								}}>
								<Stack
									direction='row'
									spacing={1}>
									<IconButton color='primary'>
										<StoreIcon />
									</IconButton>
									<IconButton color='primary'>
										<StoreIcon />
									</IconButton>
								</Stack>
								<Stack
									direction='row'
									spacing={1}>
									<IconButton color='primary'>
										<StoreIcon />
									</IconButton>
									<IconButton color='primary'>
										<StoreIcon />
									</IconButton>
								</Stack>
							</Stack>
							{/* <button onClick={handleButtonClick}>Click me</button> */}
						</div>
					</InfoWindow>
				)}
				{directionsResponse && (
					<DirectionsRenderer
						directions={directionsResponse}
						options={{
							polylineOptions: {
								strokeColor: "#FF1200",
								strokeOpacity: 0.8,
								strokeWeight: 6,
								zIndex: 100, // High zIndex value to ensure the polyline is above markers
							},
							markerOptions: {
								visible: false,
							},
						}}
					/>
				)}
				{/* {directionsResponse && Destination && (
					<InfoWindow
						position={Destination}
						options={{ disableAutoPan: true }}>
						<div>
							<span>{props.onWhatDistance} away</span>
						</div>
					</InfoWindow>
				)} */}
			</GoogleMap>
			<Box
				sx={{
					display: "flex",
					position: "absolute !important",
					flexDirection: "column",
					gap: 1,
					top: {
						xs: "17%",
						lg: "14%",
					},
					left: {
						xs: "1%",
						lg: ".75%",
					},
				}}>
				<Button
					sx={{ width: "fit-content", textTransform: "none" }}
					variant='contained'
					endIcon={<GroupAddIcon />}
					onClick={() => toggleCPOButton()}>
					Be an OCPI Member(beta)
				</Button>
				<Button
					sx={{ width: "fit-content", textTransform: "none" }}
					variant='contained'
					color='warning'
					endIcon={<FilterAltIcon />}
					onClick={() => toggleFilterModal(true)}>
					Search/Filter EVSEs
				</Button>
			</Box>

			{IsFilterModalVisible && (
				<FilterModal
					onFilterDefaultData={props.onFilterDefaultData}
					onSetModalVisibility={toggleFilterModal}
					onSocket={props.onSocket}
				/>
			)}
			<Box
				sx={{
					position: "absolute",
					bottom: "1%",
					right: "1%",
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "flex-end",
					gap: 1,
					zIndex: 1,
				}}>
				<Tooltip
					title='Your Location'
					placement='left'
					className='user-location'>
					<IconButton
						onClick={() => handleReCenter()}
						className='location-btn'>
						<MyLocationIcon />
					</IconButton>
				</Tooltip>
				<Box>
					<SpeedDial
						ariaLabel='Support Speed Dial'
						// sx={{ bottom: 16, right: 16 }}
						direction={"left"}
						icon={<SupportAgentIcon />}>
						{actions.map((action) => (
							<SpeedDialAction
								key={action.name}
								icon={action.icon}
								tooltipTitle={action.name}
							/>
						))}
					</SpeedDial>
				</Box>
			</Box>
		</>
	);
};

export default MapComponent;
