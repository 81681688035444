import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import MyApp from './App';
import WifiLandingPage from './components/WifiLandingPage';
import "./assets/css/index.css";
import 'bootstrap/dist/css/bootstrap.min.css';

// ROUTES
const router = createBrowserRouter([
  {
    path: "*",
    // element: <ErrorPage />
  },
  {
    path: "/",
    element: <MyApp />,
  },
  {
    path: "/wifi-landing-page",
    element: <WifiLandingPage />,
  },


]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <RouterProvider router={router} />
);