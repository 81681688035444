import { useEffect, useState } from "react";
import "../assets/css/detailsmodal.css";
import CloseIcon from "@mui/icons-material/Close";
import SampleImage from "../assets/img/Sample.png";
import Typography from "@mui/material/Typography";
import ImageCarousel from "./ImageCarousel";
import Divider from "@mui/material/Divider";
import CheckIcon from "@mui/icons-material/Check";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoNotDisturbOnIcon from "@mui/icons-material/DoNotDisturbOn";
import OfflineBoltIcon from "@mui/icons-material/OfflineBolt";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import CancelIcon from "@mui/icons-material/Cancel";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import AssistantDirectionIcon from "@mui/icons-material/AssistantDirection";
import { Button, IconButton, Tooltip } from "@mui/material";

import GetDistance from "../functions/GetDistance";
// import { Button } from "bootstrap";

const DetailsModal = (props) => {
	const [Merchant, setMerchant] = useState({});
	const [PartyID, setPartyID] = useState();
	const [Amenities, setAmenities] = useState([]);
	const [EVSEs, setEVSEs] = useState([]);
	const [status, setstatus] = useState("");
	const [ImageList, setImageList] = useState([]);
	const [Price, setPrice] = useState(0);
	const [FloorLevel, setFloorLevel] = useState(0);
	const [Coordinates, setCoordinates] = useState({});
	const [Connectors, setConnectors] = useState();

	function isEmptyObject(obj) {
		return (
			obj && // Ensures it's not null or undefined
			typeof obj === "object" && // Ensures it's an object
			obj.constructor === Object && // Ensures it's a plain object
			Object.keys(obj).length === 0 // Ensures it has no own properties
		);
	}

	useEffect(() => {
		console.log(props.onMerchantToShow);
		console.log(props.onNavigationDetails);
		if (!isEmptyObject(props.onMerchantToShow)) {
			setMerchant(props.onMerchantToShow);
			setAmenities(props.onMerchantToShow.facilities);
			console.log("MERCHANT:", props.onMerchantToShow);
			setCoordinates({
				lat: Number(props.onMerchantToShow.coordinates.latitude),
				lng: Number(props.onMerchantToShow.coordinates.longitude),
			});
			setPartyID(props.onMerchantToShow.party_id);
			if (typeof props.onMerchantToShow.facilities == "string") {
				let x = props.onMerchantToShow.facilities;
				let y = JSON.parse(x);
				setAmenities(y);
			}
			if (props.onMerchantToShow.evses.length !== undefined) {
				console.log("DETAILS:", props.onMerchantToShow.evses);
				if (props.onMerchantToShow.evses != undefined) {
					setEVSEs(props.onMerchantToShow.evses);
					props.onMerchantToShow.evses.map((data) => {
						setstatus(data.status);
					});
				}
			}
		}
	}, [props]);

	useEffect(() => {
		console.log("[SELECTED MERCHANT]:", Merchant);
	}, [Merchant]);

	useEffect(() => {
		console.log("EVSEs:", EVSEs);
		if (EVSEs != null) {
			EVSEs?.map((data) => {
				console.log("status:", data);
				setCoordinates({
					lat: Number(data.evse_coodinates_lat),
					lng: Number(data.evse_coodinates_lng),
				});
				setstatus(data.status);
				setImageList(data.evse_images);
				setFloorLevel(data.floor_level);
				console.log(data.connectors);
				setConnectors(data.connectors);
				data.connectors.map((subdata) => {
					console.log(subdata.min_price);
					setPrice(Number(subdata.min_price));
				});
			});
		}
	}, [EVSEs]);

	useEffect(() => {
		let request = GetDistance(props.onUserLocation, Coordinates);
		request.then((res) => {
			console.log(res);
		});
		console.log(Coordinates);
	}, [Coordinates]);

	const toggleNavigate = () => {
		props.onToggleModal(false, undefined);
		props.onNavigate(props.onUserLocation, Coordinates);
		props.onSetNavigationModal(true, Merchant.name);
		props.onSetZoom();
	};

	return (
		<div className='details-modal'>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
					// marginBottom: 4,
					alignItems: "center",

					padding: "8px",
					// color: "white",
					// backgroundColor:
					// 	status === "AVAILABLE"
					// 		? "green"
					// 		: status === "CHARGING"
					// 		? "blue"
					// 		: status === "RESERVED"
					// 		? "orange"
					// 		: "red",
				}}>
				{/* <Box>
					<CloseIcon
						onClick={() => props.onToggleModal(false, undefined)}
						sx={{ cursor: "pointer" }}
					/>
					<IconButton></IconButton>
				</Box> */}
				<CloseIcon
					onClick={() => props.onToggleModal(false, undefined)}
					sx={{ cursor: "pointer" }}
				/>
				{/* 
				<Chip
					label={status ? status : "STATUS NOT AVAILABLE"}
					color={
						status === "AVAILABLE"
							? "success"
							: status === "CHARGING"
							? "primary"
							: status === "RESERVED"
							? "warning"
							: "error"
					}
					icon={
						status === "AVAILABLE" ? (
							<CheckCircleIcon />
						) : status === "CHARGING" ? (
							<OfflineBoltIcon />
						) : status === "RESERVED" ? (
							<BookmarkAddedIcon />
						) : status === "OFFLINE" ? (
							<DoNotDisturbOnIcon />
						) : (
							<CancelIcon />
						)
					}
				/> */}
			</div>
			<Box
				sx={{
					width: "100%",
					display: "flex",
					flexDirection: { lg: "row", xs: "column" },
					height: "100%",
					overflow: "auto",
				}}>
				<div className='merchant-details'>
					<div className='details-left'>
						<ImageCarousel
							onImageList={ImageList}
							sx={{ height: "100%", boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)" }}
						/>
						{/* <img src={SampleImage} style={{ height: '100%' }} /> */}
					</div>
					<div className='details-right'>
						<div
							style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "unset" }}
							gutterBottom>
							<Typography variant='h5'>{Merchant.name}</Typography>
							{/* <Typography
								variant='subtitle2'
								sx={{ fontSize: "16px", fontStyle: "italic", whiteSpace: "nowrap", alignSelf: "flex-end" }}>
								&#8369; {Price === undefined || Price === 0 ? "-" : <>{Price.toFixed(2)}/KW-H</>}
							</Typography> */}
						</div>
						<Typography
							sx={{ width: "90%" }}
							variant='subtitle2'
							color='text.secondary'>
							{/* <span>
								Floor Level:{" "}
								{FloorLevel !== undefined && FloorLevel !== null
									? Number(FloorLevel) < 0
										? "LG"
										: Number(FloorLevel) === 1
										? "UG"
										: FloorLevel + "F"
									: "N/A"}
							</span> */}
							<br />
							<a
								href={`http://maps.apple.com/?q=${Merchant.address}`}
								target='_blank'>
								{Merchant.address}, {Merchant.postal_code}
							</a>
						</Typography>

						{PartyID === "PAR" && (
							<>
								<Divider sx={{ marginTop: "8px", marginBottom: "8px", borderColor: "unset" }} />
								<div className='charger-settings-list'>
									{/* <Chip label='Current type: AC' color="primary" /> */}
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;Current type: AC
										</Typography>
									</span>
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;Power: 7KW
										</Typography>
									</span>
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;Plug type: Type 2 x 1 unit
										</Typography>
									</span>
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;With App activation via QR
										</Typography>
									</span>
									<span className='charger-settings-item'>
										<Typography
											fontSize={"16px"}
											variant='subtitle2'
											sx={{ whiteSpace: "break-spaces" }}>
											&#183;&nbsp;Payment Types available: Maya, Gcash & Credit card
										</Typography>
									</span>
								</div>
							</>
						)}
						{Amenities && Amenities !== undefined && (
							<>
								<Divider sx={{ marginTop: "8px", marginBottom: "8px", borderColor: "unset" }} />
								<div style={{ display: "flex", flexDirection: "column" }}>
									<Typography
										sx={{ fontSize: "16px" }}
										variant='subtitle2'>
										Amenities available:
									</Typography>
									<div
										style={{
											padding: "8px",
											display: "flex",
											flexDirection: "row",
											lineHeight: "16px",
											flexWrap: "wrap",
											gap: 5,
										}}>
										{Amenities &&
											Amenities !== undefined &&
											Amenities.map((item) => {
												const word = item.replaceAll("_", " ").toLowerCase();
												const firstLetter = word.charAt(0);
												const firstLetterCap = firstLetter.toUpperCase();
												const remainingLetters = word.slice(1);
												const capitalizedWord = firstLetterCap + remainingLetters;
												return (
													<Chip
														label={capitalizedWord}
														color='primary'
													/>
												);
											})}
									</div>
								</div>
							</>
						)}

						{Merchant.website !== undefined && Merchant.website !== null && (
							<>
								<Divider sx={{ marginTop: "8px", marginBottom: "8px", borderColor: "unset" }} />
								<Typography variant='subtitle2'>
									<span style={{ display: "flex", justifyContent: "space-between" }}>
										Visit our page:&nbsp;{" "}
										<a
											href={Merchant.website}
											target='_blank'>
											{Merchant.website}
										</a>
									</span>
								</Typography>
							</>
						)}
					</div>
				</div>
				<Box sx={{ height: "100%", width: "100%", padding: "16px" }}>
					<Typography
						variant='subtitle2'
						sx={{ fontSize: "24px" }}>
						Charger List
					</Typography>
					<Box sx={{ display: "flex", flexDirection: "column", gap: 1, alignItems: "center" }}>
						{EVSEs != null &&
							EVSEs?.map((data) => {
								return (
									<Box sx={{ backgroundColor: "gainsboro", width: "95%", display: "flex", borderRadius: "16px" }}>
										{/* <Box sx={{ width: "20%", height: "110px", backgroundColor: "black" }}></Box> */}
										<Box
											sx={{
												width: "100%",
												padding: "8px",
												display: "flex",
												flexDirection: "column",
												// alignItems: "flex-end",
											}}>
											<Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
												<Typography
													variant='h5'
													sx={{ fontWeight: "bold" }}>
													{data.serial_number}
												</Typography>
												<Chip
													label={data.status ? data.status : "STATUS NOT AVAILABLE"}
													color={
														data.status === "AVAILABLE"
															? "success"
															: data.status === "CHARGING"
															? "primary"
															: data.status === "RESERVED"
															? "warning"
															: "error"
													}
													icon={
														data.status === "AVAILABLE" ? (
															<CheckCircleIcon />
														) : data.status === "CHARGING" ? (
															<OfflineBoltIcon />
														) : data.status === "RESERVED" ? (
															<BookmarkAddedIcon />
														) : data.status === "OFFLINE" ? (
															<DoNotDisturbOnIcon />
														) : (
															<CancelIcon />
														)
													}
												/>
											</Box>
											<Box sx={{ paddingLeft: "8px" }}>
												<Typography variant='subtitle2'>
													Floor Level:{" "}
													{data.floor_level !== undefined && data.floor_level !== null
														? Number(data.floor_level) < 0
															? "LG"
															: Number(data.floor_level) === 1
															? "UG"
															: data.floor_level + "F"
														: "N/A"}
												</Typography>
												<Typography variant='subtitle2'>{data.meter_type}</Typography>
											</Box>

											<Box sx={{ marginTop: "16px" }}>
												<Typography variant='subtitle2'>Connector/Plug List</Typography>
												<Box sx={{ display: "flex", flexDirection: "column", gap: 1, padding: "8px" }}>
													{Connectors !== undefined &&
														Connectors.length > 0 &&
														Connectors.map((data) => {
															console.log(data);
															return (
																<Box sx={{ backgroundColor: "white", borderRadius: "16px", padding: "8px" }}>
																	<Box sx={{ display: "flex", justifyContent: "space-between" }}>
																		<Typography variant='subtitle2'>ID: {data.connector_id}</Typography>
																		<Chip
																			label={data.status ? data.status : "STATUS NOT AVAILABLE"}
																			color={
																				data.status === "AVAILABLE"
																					? "success"
																					: data.status === "CHARGING"
																					? "primary"
																					: data.status === "RESERVED"
																					? "warning"
																					: "error"
																			}
																			icon={
																				data.status === "AVAILABLE" ? (
																					<CheckCircleIcon />
																				) : data.status === "CHARGING" ? (
																					<OfflineBoltIcon />
																				) : data.status === "RESERVED" ? (
																					<BookmarkAddedIcon />
																				) : data.status === "OFFLINE" ? (
																					<DoNotDisturbOnIcon />
																				) : (
																					<CancelIcon />
																				)
																			}
																		/>
																	</Box>
																	<Typography variant='subtitle2'>{data.power_type}</Typography>
																	<Typography variant='subtitle2'>{data.standard}</Typography>
																	<Typography variant='subtitle2'>&#8369; {data.rate_setting}</Typography>
																</Box>
															);
														})}
												</Box>
											</Box>
										</Box>
									</Box>
								);
							})}
					</Box>
				</Box>
			</Box>

			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",

					// marginBottom: 4,
					alignItems: "center",
					boxShadow: "0 1px 6px rgba(0, 0, 0, 0.12), 0 1px 4px rgba(0, 0, 0, 0.24)",
					padding: "8px",
					// color: "white",
					// backgroundColor:
					// 	status === "AVAILABLE"
					// 		? "green"
					// 		: status === "CHARGING"
					// 		? "blue"
					// 		: status === "RESERVED"
					// 		? "orange"
					// 		: "red",
				}}>
				{/* <Typography
					variant='subtitle2'
					sx={{ fontSize: "16px", fontStyle: "italic" }}>
					{props?.onWhatDistance} away
				</Typography> */}
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column", // maxWidth 800px on medium screens
							lg: "row",
						},
						alignItems: "flex-end",
						width: "100%",
						gap: 1,
						justifyContent: "flex-end",
					}}>
					{/* <Tooltip
						arrow
						title='Coming Soon!'
						placement='top-end'
						sx={{
							width: {
								xs: "100%", // maxWidth 800px on medium screens
								lg: "40%", // maxWidth 1000px on large screens
							},
						}}>
						<Box>
							<Button
								sx={{ width: "100%" }}
								variant='contained'
								color='primary'
								disabled>
								Start
							</Button>
						</Box>
					</Tooltip>

					<Tooltip
						arrow
						title='Coming Soon!'
						placement='top-end'
						sx={{
							width: {
								xs: "100%", // maxWidth 800px on medium screens
								lg: "40%", // maxWidth 1000px on large screens
							},
						}}>
						<Box>
							<Button
								sx={{ width: "100%" }}
								variant='contained'
								color='warning'
								disabled>
								Reserve
							</Button>
						</Box>
					</Tooltip> */}

					<Button
						sx={{
							width: {
								xs: "100%", // maxWidth 800px on medium screens
								lg: "40%", // maxWidth 1000px on large screens
							},
						}}
						variant='contained'
						color='success'
						onClick={() => toggleNavigate()}>
						Navigate ({!isEmptyObject(props.onNavigationDetails) && props.onNavigationDetails?.distance.text} away)
					</Button>
				</Box>
			</div>
		</div>
	);
};

export default DetailsModal;
