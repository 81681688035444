const GetDistance = async (fromLoc, toLoc) => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
    const origin = `${toLoc.lat},${toLoc.lng}`; // San Francisco
    const destination = `${toLoc.lat},${toLoc.lng}`; // Los Angeles

    const url = `https://maps.googleapis.com/maps/api/distancematrix/json?origins=${origin}&destinations=${destination}&key=${apiKey}`;

    try {
        const response = await fetch(url);
        if (!response.ok) {
            throw new Error('Failed to fetch data');
        }
        const result = await response.json();
        const distanceInMeters = result.rows[0].elements[0].distance.value;

        return distanceInMeters;
    } catch (error) {
        console.error('Error fetching data: ', error);
    }
}

export default GetDistance;