import DetailsModal from "./components/DetailsModal";
import MapComponent from "./components/MapComponent";
import BottomModal from "./components/BottomModal";
import SystemLogo from "./assets/img/system-logo.png";
import ParkNchargeLogo from "./assets/img/parkncharge-logo.svg";
import BlackBackground from "./components/BlackBackground";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { io } from "socket.io-client";
import React, { useEffect, useState, useRef } from "react";
import { Typography, Box } from "@mui/material";
import { Nav } from "react-bootstrap";

import GetFilterDefaultData from "./functions/GetFilterDefaultData";

const token = process.env.REACT_APP_TOKEN;
// https://stg-ocpi.sysnetph.com/ocpi/ws/authenticate
const socket = io(process.env.REACT_APP_MODE === "PROD" ? "https://emsp-socketserver.parkncharge.com.ph/authenticate" : "https://stg-ocpiwss.sysnetph.com/authenticate", {
  transports: ['websocket'],
  withCredentials: true,
  rejectUnauthorized: false,
  auth: {
    token: token
  }
});

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const App = () => {
  const [EVSEsDetails, setEVSEsDetails] = useState([]);
  const [IsModalVisible, setIsModalVisible] = useState(false);
  const [IsNavigationModalVisible, setIsNavigationModalVisible] = useState(false);
  const [CurrentMerchant, setCurrentMerchant] = useState('');
  const [MerchantDetails, setMerchantDetails] = useState({})
  const [UserLocation, setUserLocation] = useState({});
  const [IsAccurateLocation, setIsAccurateLocation] = useState(false);
  const [UpdateEVSE, setUpdateEVSE] = useState({});
  const [open, setOpen] = useState(false);
  const [NavigationDetails, setNavigationDetails] = useState({});
  const [FilterDefaultData, setFilterDefaultData] = useState();
  const [DirectionRespose, setDirectionResponse] = useState("");
  const [NavDestination, setNavDestination] = useState("");

  const [Navigate, setNavigate] = useState(() => { });
  const [ZoomFunc, setZoomFunc] = useState(() => { });
  const [CancelNavigation, setCancelNavigation] = useState(() => { });
  const directionsServiceRef = useRef(null);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const options = {
    enableHighAccuracy: true,
    timeout: 10000,
  };


  const successCallback = (position) => {
    setUserLocation({
      lat: Number(position.coords.latitude),
      lng: Number(position.coords.longitude)
    });

    if (position.coords.accuracy <= 100) {
      setIsAccurateLocation(true)
    } else {
      handleClick();
      setIsAccurateLocation(false);
    }
  };

  const errorCallback = (error) => {
    console.log(error);
  };

  const toggleDistance = (navDetails, func = undefined) => {
    setNavigationDetails(navDetails)
    if (func !== undefined) {
      setCancelNavigation(() => func);
    }
  }

  const toggleDirectionResponse = (directionRes) => {
    setDirectionResponse(directionRes);
  }

  const handleNavigationRef = (navRef) => {
    directionsServiceRef.current = navRef;
  }

  const toggleFilter = () => {
    socket.emit(
      "location-filter-req",
      {
        data: {
          power_types: ["AC"],
          facilities: ["pool", "wifi", "mall"],
          connector_type: ["type 2"],
        },
      },
      (res) => {
        console.log("ack:", res); //server acknowledgement response
      }
    );
  };

  useEffect(() => {
    let tmp_List;

    let request = GetFilterDefaultData();
    request.then((res) => {
      if (res.status === 200) {
        console.log(res.data)
        setFilterDefaultData(res.data);
      }
    })

    // if (process.env.REACT_APP_MODE == "PROD") {
    //   console.log = function () { }
    // }


    socket.on('connection', function (socket) {
      socket.on('disconnect', function () {
        console.log("disconnect: ", socket.id);
      });
    });
    // client-side
    socket.on("connect", () => {
      console.log("SOCKET_ID:", socket.id); // x8WIv7-mJelg7on_ALbx
    });

    socket.on("location-boot", (data) => {
      console.log("BOOT", data);
      tmp_List = data;
      setEVSEsDetails(data);
    });

    socket.on("location-update", (arg, ack) => {
      console.log(arg)
      setUpdateEVSE(arg);
      if (ack) {
        ack({ msg: 'location-update received' });
      }


      // if (Object.keys(arg).length > 0) {
      //   console.log("received: " + arg)
      //   if (ack) {
      //     ack({ msg: 'location-update received' });
      //   }

      //   setUpdateEVSE(arg);
      // }
    });

    socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
      console.log(err instanceof Error); // true
      console.log(err.message); // not authorized 
    });

    navigator.geolocation.getCurrentPosition(successCallback, errorCallback, options);
  }, [])



  const toggleIsModalVisible = (bool, evseID) => {
    setIsModalVisible(bool);
    setCurrentMerchant(evseID);
    if (!bool) {
      setCurrentMerchant('');
      setMerchantDetails({});
    }
    console.log(evseID)
  }

  useEffect(() => {
    if (CurrentMerchant !== '') {

      EVSEsDetails.map((data) => {
        if (data.id === CurrentMerchant) {
          console.log("CURRENT MERCHANT DATA:", data);
          setMerchantDetails(data)
        }
      })
    }
  }, [CurrentMerchant])

  useEffect(() => {
    if (UpdateEVSE != null) {
      console.log(EVSEsDetails)
      let tmp_EVSEsDetails = [...EVSEsDetails];
      tmp_EVSEsDetails.map((tmp_data) => {
        if (tmp_data?.id === Number(UpdateEVSE.data.id)) {
          tmp_data.evses.map((tmp_subdata) => {
            if (tmp_subdata?.uid === UpdateEVSE.data.evse_uid) {
              console.log(tmp_subdata)
              tmp_subdata.status = UpdateEVSE.data.evse_status;
              tmp_subdata.connectors.map((tmp_connector_data) => {
                UpdateEVSE.data.connector_details.map((conn) => {
                  if (tmp_connector_data?.connector_id === Number(conn.connector_id)) {
                    tmp_connector_data.status = conn.connector_status;
                  }
                })

              })
            }

          })
        }
      })

      setEVSEsDetails(tmp_EVSEsDetails)
      tmp_EVSEsDetails.map((evse) => {
        console.log(evse, CurrentMerchant)
        if (evse.id === CurrentMerchant) {
          setMerchantDetails(evse)
        }
      })
    }
  }, [UpdateEVSE]);


  const handleNavigation = (nav) => {
    setNavigate(() => nav);
  }

  const handleZoom = (func) => {
    setZoomFunc(() => func);
  }

  const toggleNavigationModal = (bool, destination = undefined) => {
    setIsNavigationModalVisible(bool);
    if (destination !== undefined) {
      setNavDestination(destination)
    }
  }

  useEffect(() => {
    console.log("NAVIGATE:", Navigate)
  }, [Navigate])

  return (
    <div className="App">
      {
        IsModalVisible && <BlackBackground onToggleModal={toggleIsModalVisible} />
      }
      {
        IsModalVisible &&
        <DetailsModal onToggleModal={toggleIsModalVisible} onMerchantList={EVSEsDetails} onMerchantToShow={MerchantDetails} onUserLocation={UserLocation} onNavigationDetails={NavigationDetails} onNavigate={Navigate} onSetNavigationModal={toggleNavigationModal} onSetZoom={ZoomFunc} />
      }

      <div className="app-header">
        <img className="app-system-logo" src={SystemLogo} />
        <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", padding: { lg: "16px" } }}>
          <Typography className="banner-message" variant="h5" component="div" sx={{ fontStyle: 'italic', color: "#125EDE", fontWeight: "bold" }}>Dedicated in helping EVs to travel farther.</Typography>
          <Typography className="banner-message" variant="h5" component="div" sx={{ fontStyle: 'italic', color: "#125EDE", fontWeight: "bold" }}>An OCPI Hub initiative.</Typography>
        </Box>

      </div>
      <MapComponent mapDetails={EVSEsDetails} onToggleModal={toggleIsModalVisible} onUserLocation={UserLocation} onIsAccurateLocation={IsAccurateLocation} onToggleDistance={toggleDistance} navigationRef={directionsServiceRef} onSetNavigationFunc={handleNavigation} onSetZoomFunc={handleZoom} onNavigationDetails={NavigationDetails} onFilterDefaultData={FilterDefaultData} onSocket={toggleFilter} />
      {IsNavigationModalVisible && <BottomModal onNavigationDetails={NavigationDetails} onSetNavigationModal={toggleNavigationModal} onCancelNavigation={CancelNavigation} onNavDestination={NavDestination} />}

      <div className="app-footer">
        <Typography variant="subtitle2" sx={{ textAlign: "center" }}>Acknowledgement to the Department of Energy for providing public information on EV sites and partners.</Typography>
        <Typography variant="subtitle2" sx={{ marginTop: "8px", textAlign: "center" }}>This site is solely dedicated to the EV industry and EV users in the Philippines, providing valuable resources and support for the growing community.</Typography>
        <Typography variant="subtitle2" sx={{ marginTop: "16px", textAlign: "center" }}>&copy; 2024 FindEVPlugPH. All rights reserved.</Typography>
      </div>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Location not found. Please check if your device location is enabled.
        </Alert>
      </Snackbar>
    </div>
  );
};

export default App;