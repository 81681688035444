import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import InfoIcon from "@mui/icons-material/Info";
import "../assets/css/wifilandingpage.css";
import PromotionImage from "../assets/img/page_image.jpg";
import EVAPLogo from "../assets/img/EVAP_Logo.jpg";

export default function WifiLandingPage() {
	const [width, setWidth] = useState(Number(window.innerWidth));

	const isMobile = width <= 768;

	useEffect(() => {
		// Add smooth scrolling to anchor links
		document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
			anchor.addEventListener("click", function (e) {
				e.preventDefault();

				const targetId = this.getAttribute("href").substring(1);
				const targetElement = document.getElementById(targetId);

				if (targetElement) {
					const parentDoc = window.parent.document;
					const parentScrollY = targetElement.getBoundingClientRect().top + parentDoc.documentElement.scrollTop;
					parentDoc.body.scrollTo({
						top: parentScrollY,
						behavior: "smooth",
					});
				}
			});
		});

		window.addEventListener("resize", handleWindowSizeChange);
		return () => {
			window.removeEventListener("resize", handleWindowSizeChange);
		};
	}, []);

	function handleWindowSizeChange() {
		setWidth(window.innerWidth);
	}

	const handleLinkClick = (href) => {
		// Use window.location to navigate to the specified URL
		window.location.href = href;
	};

	return (
		<div id='main'>
			<div
				className='side-form'
				id='evap_survey'>
				<iframe
					className='frame'
					src='https://docs.google.com/forms/d/e/1FAIpQLSfYT10CYywaZNGfmPgVWiESsoYdttRgjNQRBDyEb3-TtUK9yg/viewform?embedded=true'
					width='100%'
					// height='2000'
					// style={{ minHeight: "100%" }}
				>
					Loading�
				</iframe>
			</div>
			<div className='side-image'>
				<a
					href={`${process.env.PUBLIC_URL}/assets/files/EventSchedule.pdf`}
					without
					rel='noopener noreferrer'
					target='_blank'
					id='btn_more_info'>
					See PEVS Event Schedule&nbsp;
					<InfoIcon />
				</a>
				{/* <Button sx={{ height: "10vh" }}>
					<img
						src={EVAPLogo}
						style={{ height: "100%", width: "25vw" }}
					/>
				</Button> */}
				<Button
					id='btn_to_form'
					onClick={() => handleLinkClick("#evap_survey")}>
					<img
						src={EVAPLogo}
						style={{ height: "100%" }}
					/>
					<span id='message'>Click to Join EVAP Survey and receive newsletter about EV and eMobility</span>
					{/* <span id='icon'>
						<ExpandCircleDownIcon fontSize='large' />
					</span> */}
				</Button>
				<img
					src={PromotionImage}
					id='display_image'
					alt='Image-Promotion'
				/>
			</div>
		</div>
	);
}
