const GetFilterDefaultData = async () => {
    const myHeaders = new Headers();
    myHeaders.append("Authorization", "Basic " + process.env.REACT_APP_API_TOKEN);

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
    };

    let response;

    try {
        response = await fetch(
            `${process.env.REACT_APP_API_ADDRESS}/ocpi/cpo/2.2/filters`,
            requestOptions
        );

        return response.json();

    } catch (error) {
        console.log("Network error or other unexpected issue:", error);
    }
}

export default GetFilterDefaultData;